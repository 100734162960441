const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry , index) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('slide-in--show')
            const transitionDelay = index * 200
            entry.target.style.transitionDelay = `${transitionDelay}ms`
        }
    })
})

const hiddenElements = document.querySelectorAll('.slide-in--hidden')
hiddenElements.forEach((el) => {
    observer.observe(el)
})
